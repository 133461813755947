<template>
  <div  class="p-4 my-4 text-sm rounded bg-blue-900" role="alert">
    <h2 class="text-white text-lg mb-4">Ссылки на документы (для физических лиц)</h2>
    <a class="text-white  cursor-pointer block hover:text-blue-300 mb-2" :key="'doc-'+idx" v-for="(doc,idx) in applicationInfoDocs" :href="doc.link" target="_blank">{{doc.name}}</a>
  </div>
</template>
<script>
export default {
  data:() => ({
    applicationInfoDocs:[]
  }),
  created() {
    this.fetchApplicationInfoDocs()
  },
  methods:{
    fetchApplicationInfoDocs(){
      this.$store.dispatch('news/getContractDocuments', 7348).then(res => {
        this.applicationInfoDocs = res.data.data
      })
    },
  }

}
</script>