<template>
  <div class="organization">
    <div class="organization__wrapper">
      <h1 class="organization__title">Заявка</h1>
      <small class="cursor-pointer text-teal-500" @click="$router.back()">Назад</small>

      <div v-if="!user.uf_telegram_id"
           class="p-4 my-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
           role="alert">
        <span class="font-medium">Внимание!</span> Ваш аккаунт не привязан к телеграм-боту, где вы можете получать
        уведомления.
        Привязать учетную запись можно
        <router-link class="text-blue-500" to="/user/dashboard/user-edit">здесь.</router-link>
      </div>


      <div class="organization__form-section">
        <h3 class="mb-5">Паспортные данные</h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>
            <label>
              Тип документа*
              <Autocomplete
                  v-model="form.uf_passport_doctype_name"
                  :items="docTypes"
                  search-by="code"
                  item-text="name"
                  item-value="name"/>
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_doctype_name.required && $v.form.uf_passport_doctype_name.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>
            <label>
              Фамилия*
              <input :style="{
                              'background-color': dark ? '#262626':'grey'
                            }" disabled type="text" placeholder="" v-model="form.uf_passport_surname">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_surname.required && $v.form.uf_passport_surname.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>

            <label for="">
              Имя*
              <input :style="{
                              'background-color': dark ? '#262626':'grey'
                            }" disabled type="text" v-model="form.uf_passport_name">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_name.required && $v.form.uf_passport_name.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>
            <label for="">
              Отчество
              <input :style="{
                              'background-color': dark ? '#262626':'grey'
                            }" disabled type="text" placeholder="" v-model="form.uf_passport_middlename">
            </label>

            <label for="">
              Дата рождения*
              <input type="date" v-model="form.uf_passport_birthday">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_birthday.required && $v.form.uf_passport_birthday.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>

            <label for="">
              Серия и номер паспорта (на латинском)*
              <input type="text" v-model="form.uf_passport_number">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_number.required && $v.form.uf_passport_number.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_number.sameAsTrue && $v.form.uf_passport_number.$dirty">
                Номер не соответствует структуре
              </small>
            </label>

            <label for="">
              Дата выдачи*
              <input type="date" v-model="form.uf_passport_date_on">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_date_on.required && $v.form.uf_passport_date_on.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_date_on.sameAsTrue && $v.form.uf_passport_date_on.$dirty">Неверный
                формат даты</small>
            </label>

            <label for="">
              Дата окончания*
              <input type="date" v-model="form.uf_passport_date_off">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_date_off.required && $v.form.uf_passport_date_off.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_date_off.sameAsTrue && $v.form.uf_passport_date_off.$dirty">Неверный
                формат даты</small>
              <small class="text-red-600"
                     v-if="expired">Срок действия истек</small>
            </label>
            <label for="">
              Идентификационный номер (на латинском)*
              <input type="text" v-model="form.uf_passport_person_id">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_person_id.required && $v.form.uf_passport_person_id.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_person_id.sameAsTrue && $v.form.uf_passport_person_id.$dirty">
                Номер не соответствует структуре
              </small>
            </label>

          </div>
          <div>
            <label for="">
              Почтовый индекс
              <input type="text" v-model.trim="form.uf_passport_index">
            </label>
            <label for="">
              Область
              <input type="text" v-model.trim="form.uf_passport_region">
            </label>

            <label>
              Город*
              <input type="text" v-model.trim="form.uf_passport_city">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_city.required && $v.form.uf_passport_city.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


            <label>
              Улица*
              <input type="text" v-model.trim="form.uf_passport_street">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_street.required && $v.form.uf_passport_street.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>

            <label>
              Дом*
              <input type="text" v-model.trim="form.uf_passport_house">
              <small class="text-red-600"
                     v-if="!$v.form.uf_passport_house.required && $v.form.uf_passport_house.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>

            <label for="">
              Помещение
              <input type="text" placeholder="" v-model.trim="form.uf_passport_room">
            </label>


          </div>


        </div>
      </div>

      <div class="organization__form-section w-full lg:w-1/3">
        <h3 class="mb-5">Льготы</h3>
        <label for="">
          Наличие льготы *
          <Autocomplete
              v-model="form.uf_licence_type"
              :items="licenceTypes"
              search-by="name"
              item-text="name"
              item-value="value"/>
          <small class="text-red-600"
                 v-if="!$v.form.uf_licence_type.required && $v.form.uf_licence_type.$dirty">
            {{ $t('forms.validation.required') }}
          </small>
        </label>


      </div>

      <div class="organization__form-section">
        <h3 class="mb-5">Информация о ТС</h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>


            <label for="">
              Марка* (если отсутствует - "прочие")
              <Autocomplete
                  v-model="form.uf_brand"
                  :items="brandsList"
                  search-by="name"
                  item-text="name"
                  item-value="name"/>
              <small class="text-red-600" v-if="!$v.form.uf_brand.required && $v.form.uf_brand.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>
            <label for="">
              Модель *
              <input type="text" v-model.trim="form.uf_model">
              <small class="text-red-600" v-if="!$v.form.uf_model.required && $v.form.uf_model.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>
            <label for="">
              VIN ТС *
              <input type="text" v-model.trim="form.uf_vin">
              <small class="text-red-600" v-if="!$v.form.uf_vin.required && $v.form.uf_vin.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
              <small class="text-red-600"
                     v-if="!$v.form.uf_vin.sameAsTrue && $v.form.uf_vin.$dirty">VIN не соответствует
                формату</small>
            </label>
            <label>
              Категория ТС *
              <Autocomplete
                  v-model="form.uf_vehicle_category"
                  :items="categoriesList"
                  search-by="code"
                  item-text="text"
                  item-value="code"/>
              <small class="text-red-600"
                     v-if="!$v.form.uf_vehicle_category.required && $v.form.uf_vehicle_category.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


            <label for="">
              Тип ТС *
              <Autocomplete
                  v-model="form.uf_vehicle_type"
                  :items="typesList"
                  search-by="name"
                  item-text="name"
                  item-value="code"/>

              <small class="text-red-600"
                     v-if="!$v.form.uf_vehicle_type.required && $v.form.uf_vehicle_type.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


            <label for="">
              Тип двигателя *
              <Autocomplete
                  @input="onEngineChange"
                  v-model="form.uf_vehicle_engine_type"
                  :items="engineTypes"
                  search-by="name"
                  item-text="name"
                  item-value="value"/>

              <small class="text-red-600"
                     v-if="!$v.form.uf_vehicle_engine_type.required && $v.form.uf_vehicle_engine_type.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


          </div>
          <div>
            <label for="">
              Стоимость ТС *
              <input type="text" :value="form.uf_cost" @input="(e) => replaceCommas(e,'uf_cost')"
                     @change="onCostChange">
              <small class="text-red-600" v-if="!$v.form.uf_cost.required && $v.form.uf_cost.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>
            <label for="">
              Валюта инвойса (Валюта счёта)» *
              <Autocomplete
                  v-model="form.uf_cost_currency"
                  :items="currenciesList"
                  search-by="search"
                  item-text="text"
                  item-value="letterCode"/>

              <small class="text-red-600"
                     v-if="!$v.form.uf_cost_currency.required && $v.form.uf_cost_currency.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


            <label for="">
              Объем двигателя (см3)
              <input
                  :disabled="form.uf_vehicle_engine_type === 'электромобиль'"
                  type="text" :value="form.uf_volume" @input="(e) => replaceCommas(e,'uf_volume')">
            </label>
            <label for="">
              Момент выпуска * <span
                v-tooltip="'Если нет информации о дате выпуска, необходимо ввести 15 число;<br> Если нет информации о дате и месяце выпуска, необходимо ввести 1 июля'">
                            <i class="fas fa-info-circle"></i>
                        </span>
              <input type="date" v-model.trim="form.uf_date">
              <small class="text-red-600 py-0" v-if="!$v.form.uf_date.required && $v.form.uf_date.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
              <small class="text-red-600" v-if="compareDates">
                Выбранная дата превышает текущую
              </small>
              <small class="text-red-600"
                     v-if="!$v.form.uf_date.sameAsTrue && $v.form.uf_date.$dirty">Неверный
                формат даты</small>
            </label>


            <label>
              Кол-во мест * <span v-tooltip="'Количество мест для сидения, помимо места для водителя'">
                            <i class="fas fa-info-circle"></i>
                        </span>
              <input type="text" :value="form.uf_place_count"
                     @input="(e) => replaceCommas(e,'uf_place_count')">
              <small class="text-red-600"
                     v-if="!$v.form.uf_place_count.required && $v.form.uf_place_count.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


            <label>
              Масса (т)
              <input type="text" :value="form.uf_weight" @input="(e) => replaceCommas(e,'uf_weight')">
            </label>


            <label for="">
              Номер ПТО * (либо ТС не на ПТО)
              <Autocomplete
                  v-model="form.uf_pto"
                  :items="ptoList"
                  search-by="name"
                  item-text="name"
                  item-value="value"/>
              <small class="text-red-600" v-if="!$v.form.uf_pto.required && $v.form.uf_pto.$dirty">
                {{ $t('forms.validation.required') }}
              </small>
            </label>


          </div>
        </div>

      </div>

      <!--                <div  class="hidden organization__form-section">-->
      <!--                    <div class="flex flex-col">-->
      <!--                        <div>-->
      <!--                            <input type="radio" id="r1" name="send" value="У меня есть справка от дилера" v-model="form.uf_dealer_doc">-->
      <!--                            <label id="l1" class="ml-5" for="r1">У меня есть справка от дилера</label>-->
      <!--                        </div>-->
      <!--                        <div>-->
      <!--                            <input type="radio" id="r2" name="send" value="Я предоставлю справку от дилера"-->
      <!--                                   v-model="form.uf_dealer_doc">-->
      <!--                            <label id="l2" class="ml-5" for="r2">Я предоставлю справку от дилера</label>-->
      <!--                        </div>-->
      <!--                        <div>-->
      <!--                            <input type="radio" id="r3" name="send" value="Поручаю РУП «Белтаможсервис»" v-model="form.uf_dealer_doc">-->
      <!--                            <label id="l3" class="ml-5" for="r3">Поручаю РУП «Белтаможсервис»</label>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->

      <div class="organization__form-section">
        <div class="sm:w-full lg:w-1/3">
          <label>
            Контактный номер* (+375 ...)
            <input type="text" v-model.trim="form.uf_contact_phone">
            <small class="text-red-600"
                   v-if="!$v.form.uf_contact_phone.required && $v.form.uf_contact_phone.$dirty">
              {{ $t('forms.validation.required') }}
            </small>
            <small class="text-red-600"
                   v-if="!$v.form.uf_contact_phone.sameAsTrue && $v.form.uf_contact_phone.$dirty">
              Не соответствует формату (+375 ...)
            </small>
          </label>
        </div>
      </div>

      <div class="organization__form-section">

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>
            <vue-dropzone ref="passport"
                          class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"
                          id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="setPassport"
                          @vdropzone-file-added-manually="setPassport"
                          @vdropzone-removed-file="removePassport"></vue-dropzone>
          </div>
          <div>
            <vue-dropzone ref="documents"
                          class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"
                          id="dropzone2" :options="dropzoneOptions2"
                          @vdropzone-file-added="setDocuments"
                          @vdropzone-file-added-manually="setDocuments"
                          @vdropzone-removed-file="removeDocuments"></vue-dropzone>
          </div>
        </div>
        <Documents/>
        <div>
          <div class="checkbox  mt-5">
            <input name="personalDataAgreement" type="checkbox" v-model="form.uf_offer_agreement">
            <label class="text-sm select-none" @click="form.uf_offer_agreement = !form.uf_offer_agreement"
                   for="personalDataAgreement">
              Согласен c заявленными РУП "Белтаможсервис" условиями публичной оферты
            </label>
          </div>
          <small class="text-red-600"
                 v-if="!$v.form.uf_offer_agreement.sameAsTrue && $v.form.uf_offer_agreement.$dirty">{{
              $t('forms.validation.required')
            }}</small>
        </div>


        <button :disabled="form.uf_offer_agreement === false"
                :class="form.uf_offer_agreement === true ? 'dark:bg-green-400 bg-green-400': 'dark:bg-gray-600 bg-gray-600'"
                class="mt-4 organization__action" @click="submit">
          <svg class="spinner float-left mr-2" viewBox="0 0 50 50" v-if="loading">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
          Перейти к оплате
        </button>
      </div>


    </div>

  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {required} from 'vuelidate/lib/validators'
import {validationMixin} from 'vuelidate'
import axios from 'axios'
import {mapGetters} from 'vuex'
import moment from 'moment'
import Autocomplete from "@/components/shared/Autocomplete.vue";
import {normalizeFloat} from "@/helpers/functions";
import Documents from "@/components/user/Documents.vue";

export default {
  mixins: [validationMixin],
  components: {vueDropzone: vue2Dropzone, Autocomplete, Documents},
  data: () => ({
    loading: false,
    ptoList: [
      {
        name: "06649 - ПТО \"Белтаможсервис-ТЛЦ\"",
        value: "06649"
      },
      {
        name: "06650 - ТЛЦ \"Минск-Белтаможсервис-2\" ",
        value: "06650"
      },
    ],
    brandsList: [],
    typesList: [],
    categoriesList: [],
    currenciesList: [],
    docTypes: [],
    licenceTypes: [
      {
        name: 'без льготы',
        value: 'без льготы',
      },
      {
        name: 'удостоверение инвалида',
        value: 'удостоверение инвалида',
      },
      {
        name: 'удостоверение многодетной семьи',
        value: 'удостоверение многодетной семьи',
      },
      {
        name: 'льгота по электрокару',
        value: 'льгота по электрокару',

      }
    ],
    engineTypes: [
      {
        name: 'бензин (в т.ч. гибрид)',
        value: 'бензин',
      },
      {
        name: 'дизель (в т.ч. гибрид)',
        value: 'дизель',
      },
      {
        name: 'электромобиль',
        value: 'электромобиль',
      }
    ],
    dropzoneOptions: {
      url: 'https://declarant.by/rest/user/transport-declaration/add',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 50000000,
      maxFiles: 3,
      uploadMultiple: true,
      addRemoveLinks: true,
      dictDefaultMessage: 'Копия паспорта',
      dictMaxFilesExceeded: 'Максимум 3 файла',
      dictRemoveFile: 'Удалить'
    },
    dropzoneOptions2: {
      url: 'https://declarant.by/rest/user/transport-declaration/add',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 50000000,
      addRemoveLinks: true,
      maxFiles: 10,
      uploadMultiple: true,
      dictDefaultMessage: 'Товаросопроводительные  документы (справка от дилера)',
      dictMaxFilesExceeded: 'Максимум 10 файлов',
      dictRemoveFile: 'Удалить'
    },
    form: {
      uf_country: '',
      uf_brand: '',
      uf_model: '',
      uf_vin: '',
      uf_cost: '',
      uf_volume: '',
      uf_date: '',
      uf_pto: '06650',
      uf_dealer_doc: '',
      uf_passport_surname: '',
      uf_passport_name: '',
      uf_passport_middlename: '',
      uf_passport_doctype_name: '',
      uf_passport_number: '',
      uf_passport_date_on: '',
      uf_passport_date_off: '',
      uf_passport_person_id: '',
      uf_passport_index: '',
      uf_passport_region: '',
      uf_passport_city: '',
      uf_passport_street: '',
      uf_passport_house: '',
      uf_passport_room: '',
      uf_licence_type: '',
      uf_vehicle_category: '',
      uf_vehicle_engine_type: '',
      uf_vehicle_type: '',
      uf_cost_currency: '',
      uf_place_count: null,
      uf_weight: null,
      uf_offer_agreement: false,
      uf_contact_phone: ''
    },
    passport: '',
    documents: '',
    payments: '',
  }),
  validations: {
    form: {
      uf_brand: {required},
      uf_model: {required},
      uf_vin: {
        required,
        sameAsTrue: vin => {
          if (!vin) return false;
          const regexp = new RegExp(/\b([(A-H|J-N|P|R-Z|0-9)]{17})\b/)
          return regexp.test(vin.toUpperCase())
        }
      },
      uf_cost: {required},
      uf_date: {
        required, sameAsTrue: value => {
          if (!value) return false
          const [year] = value?.split('-') ?? []
          return year ? year.startsWith('20') || year.startsWith('19') : false
        }
      },
      uf_pto: {required},
      uf_passport_surname: {required},
      uf_passport_name: {required},
      uf_passport_doctype_name: {required},
      uf_passport_number: {
        required,
        sameAsTrue: value => {
          if (!value) return false;
          const regexp = new RegExp(/\b([A-z]{2}[0-9]{7})\b/)
          return regexp.test(value.toUpperCase())
        }
      },
      uf_passport_birthday: {
        required,
        sameAsTrue: value => {
          if (!value) return false
          const [year] = value?.split('-') ?? []
          return year ? year.startsWith('20') || year.startsWith('19') : false
        }
      },
      uf_passport_date_on: {
        required,
        sameAsTrue: value => {
          if (!value) return false
          const [year] = value?.split('-') ?? []
          return year ? year.startsWith('20') || year.startsWith('19') : false
        }
      },
      uf_passport_date_off: {
        required,
        sameAsTrue: value => {
          if (!value) return false
          const [year] = value?.split('-') ?? []
          return year ? year.startsWith('20') || year.startsWith('19') : false
        }
      },
      uf_passport_person_id: {
        required,
        sameAsTrue: value => {
          if (!value) return false;
          const regexp = new RegExp(/\b([0-9]{7}[A-z]{1}[0-9]{3}[A-z]{2}[0-9]{1})\b/)
          return regexp.test(value.toUpperCase())
        }
      },
      uf_passport_city: {required},
      uf_passport_street: {required},
      uf_passport_house: {required},
      uf_licence_type: {required},
      uf_vehicle_category: {required},
      uf_vehicle_engine_type: {required},
      uf_vehicle_type: {required},
      uf_cost_currency: {required},
      uf_place_count: {required},
      uf_offer_agreement: {
        sameAsTrue: value => value === true
      },
      uf_contact_phone: {
        required,
        sameAsTrue: value => {
          if (!value) return false;
          return value.startsWith('+375')
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      dark: 'darkTheme'
    }),
    expired(){
      if(this.form.uf_passport_date_off){
        return  moment(this.form.uf_passport_date_off, 'YYYY-MM-DD').isSameOrBefore(moment())
      }
      return false
    },
    // brandsListFiltered(){
    //     const search = this.form.uf_brand.toLowerCase()
    //     return search ?
    //      this.brandsList.filter(i => i?.search.indexOf(this.form.uf_brand?.toLowerCase()) !== -1): this.brandsList
    // },
    compareDates() {
      if (this.form.uf_date === '') {
        return false
      } else {
        const dateB = moment(new Date());
        const dateC = moment(this.form.uf_date);
        const compare = dateB.diff(dateC, 'days')
        return compare <= 0;
      }
    }
  },
  created() {
    this.fetchBrandsList()
    this.fetchTransportTypes()
    this.fetchTransportCategories()
    this.fetchCurrencies()
    this.fetchDocTypes()
    this.fillFromAuth()
  },
  methods: {
    normalizeFloat,
    replaceCommas(e, field) {
      this.form[field] = e.target.value.toString().replace(',', '.')
    },

    fillFromAuth() {
      const {
        uf_person_id,
        uf_passport,
        personal_zip,
        name,
        last_name,
        personal_city,
        personal_state,
        second_name,
        personal_street
      } = this.user
      this.form = {
        ...this.form,
        uf_passport_person_id: uf_person_id,
        uf_passport_number: uf_passport,
        uf_passport_index: personal_zip,
        uf_passport_name: name,
        uf_passport_middlename: second_name,
        uf_passport_surname: last_name,
        uf_passport_city: personal_city,
        uf_passport_region: personal_state,
        uf_passport_street: personal_street
      }

    },
    fetchDocTypes() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiIdentityDocuments').then(res => {
        this.docTypes = res.data?.list?.filter((item) => item.code.startsWith('BY')) ?? []
      })
    },
    fetchCurrencies() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiCurrencies').then(res => {
        this.currenciesList = res.data?.list.map(item => {
          return {
            ...item,
            text: `${item.letterCode} (${item.name})`,
            search: [item.letterCode, item.name, item.digitCode, item.ownName].join('-').toLowerCase(),
          }

        }) ?? []
      })
    },
    fetchTransportTypes() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiVehicleTypes').then(res => {
        this.typesList = res.data?.list?.filter((item) => item.code.startsWith('3')) ?? []
      })
    },
    fetchTransportCategories() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiTransportCategories').then(res => {
        this.categoriesList = res.data?.list?.filter((item) => !item.code.startsWith('S')) ?? []
        this.categoriesList = this.categoriesList.map((item) => {
          return {
            ...item,
            text: `${item.code} - ${item.name}`
          }

        })
      })
    },
    fetchBrandsList() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiTransportMarks').then(res => {
        this.brandsList = res.data?.list?.map(item => {
          return {
            ...item,
            search: [item.name, item.own_name].join('-')
          }
        }) ?? []
      })
    },
    setPassport(file) {
      this.passport = file.name
    },
    removePassport() {
      this.passport = ''
    },
    setDocuments(file) {
      this.documents = file.name
    },
    removeDocuments() {
      this.documents = ''
    },
    onEngineChange(v) {
      if (v === 'электромобиль' && this.form.uf_volume) {
        this.form.uf_volume = ''
      }
    },
    onCostChange(event) {
      this.form.uf_cost = normalizeFloat({value: event.target.value, afterPoint: 2})
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid || this.expired) {
        return this.$notify({
          title: 'Ошибка',
          text: 'Проверьте правильность заполнения формы',
          type: 'error'
        })
      }
      this.loading = true
      let payload = JSON.parse(JSON.stringify({
        ...this.form,
        uf_passport_doctype: this.docTypes.find(i => i.name === this.form.uf_passport_doctype_name).code
      }))
      let data = new FormData()
      let passport = this.$refs.passport.getQueuedFiles()
      let documents = this.$refs.documents.getQueuedFiles()


      if (!passport.length || !documents.length) {
        return this.$notify({
          title: 'Ошибка',
          text: 'Обязательные документы: Паспорт, Товарносопроводительные',
          type: 'error'
        })
      }

      data.append('data', JSON.stringify(payload))

      passport.forEach((file, index) => {
        data.append(`passport-${index}`, file)
      })
      documents.forEach((file, index) => {
        data.append(`documents-${index}`, file)
      })


      axios({
        method: 'post',
        url: 'https://declarant.by/rest/user/transport-declaration/add',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }).then(res => {
        this.loading = false
        this.$notify({
          title: 'Заявка сохранена',
          text: res.data.data,
          type: 'success'
        })
        this.$router.push({
          name: 'TransportDeclarationPayment', params: {
            id: res.data.id
          }
        })
      }).catch(err => {
        this.loading = false
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    },

  },

}
</script>

<style scoped>
.tooltip {
  display: none !important;
}

i:hover + .tooltip {
  display: block;
}

.checkbox input {
  opacity: 0;
  position: absolute;
}

.checkbox input + label {
  display: inline-block;
  padding: 2px;
  margin: 2px 8px -2px 0;
  cursor: pointer;
  line-height: 22px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.checkbox input + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 6px;
  border-radius: 6px;
  height: 20px;
  width: 20px;
  border: 2px solid gray;

}

.checkbox input:checked + label {
  color: rgb(52, 211, 153);
}

.checkbox input:checked + label:before {
  background: rgb(52, 211, 153);
  border-color: rgb(52, 211, 153);
}

label {
  display: block;
  margin: 0 0 10px 0;
}

select {
  margin: 0 !important;
}


.organization {
  @apply my-10  mx-auto relative
  xl:container xl:px-5;
}

.organization__wrapper {
  @apply relative;
}

.organization__title {
  @apply text-3xl
}

.organization__nav {
  @apply flex justify-center py-5 w-full
  sm:flex-col
  xl:flex-row
}

.organization__nav-item {
  @apply border dark:border-gray-700 border-gray-300  w-full
  sm:p-2
  xl:p-7

}

.organization__nav-item-text {
  @apply text-2xl font-semibold
}

.organization__form-section {
  @apply py-5
}

.organization__form-section select {
  min-height: 44px !important;
}

.organization__form-files {
  @apply flex items-end my-5
  sm:flex-col
  xl:flex-row
}

.organization__file-input-wrapper {
  @apply sm:w-full
  xl:w-1/2 xl:mx-1
}

.organization__file-input-title {
  @apply text-xl mb-2
}

.organization__check-wrapper {
  @apply flex mb-1
  sm:flex-col
  xl:flex-row
}

.organization__check-group {
  @apply dark:bg-gray-800 bg-blue-100 flex items-center justify-center text-center
  sm:w-full sm:py-2
  xl:w-1/4 xl:py-0
}

input[type=text], select {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  rounded bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white;
}

.organization__dropzone {
  @apply dark:bg-gray-800 rounded bg-gray-300 dark:hover:border-gray-800 dark:border-gray-700
  sm:mb-10
  xl:mb-0
}

.organization__check-result {
  @apply
  sm:w-full
  xl:w-3/4
}

.organization__check-text {
  @apply px-3 py-1
}

.organization__rule {
  @apply px-5
}

.organization__actions-wrapper {
  @apply flex justify-center
}

.organization__action {
  @apply px-5 py-3 rounded
}

.file-loader {
  @apply absolute w-1/4 top-0 right-0 flex items-center rounded p-5 bg-blue-300 opacity-90 z-30;
}

select::-webkit-scrollbar {
  display: none;
}

.group-select:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}

.group-select:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

* >>> .dz-progress {
  display: none !important;
  opacity: 0 !important;
}

.datepicker {
  width: 100% !important;
}

input, .datepicker {
  max-height: 44px !important;
  margin: 0 !important;
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent rounded
  bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white
}

input[type="radio"] {
  width: inherit;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  border: 1px solid #2196F3;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 20px;
  height: 20px;
  position: relative;
}

.spinner .path {
  stroke: rgb(255, 255, 255);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


</style>